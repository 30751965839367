
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
import CloudShowItem from "@/views/CloudTheatreDetails/components/CloudShowItem.vue";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
    ItemTitle,
    CloudShowItem,
  },
})
export default class CloudShow extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public showList!: Array<cloundtheater.VideoRecommendProductDto>;
  leftList: Array<cloundtheater.VideoRecommendProductDto> = [];
  rightList: Array<cloundtheater.VideoRecommendProductDto> = [];
  leftHight = 0;
  rightHight = 0;
  @Watch("showList", { immediate: true, deep: true })
  updateClassify(): void {
    this.leftHight = 0;
    this.rightHight = 0;
    this.leftList = [];
    this.rightList = [];
    this.dealInfo(this.showList);
  }
  dealInfo(allData: Array<cloundtheater.VideoRecommendProductDto>): void {
    //定义两个临时的变量来记录左右两栏的高度，避免频繁调用setData方法
    let leftH = this.leftHight;
    let rightH = this.rightHight;
    let leftData = [];
    let rightData = [];
    let imgHeight = 460;
    for (let i = 0; i < allData.length; i++) {
      let titleHeight = allData[i].productName
        ? Math.ceil(Number(allData[i].productName!.length - 1) / 10) * 42
        : 0;
      let couponsLabelHight = allData[i].showStartToEndTime ? 28 : 0;

      let currentItemHeight = titleHeight + couponsLabelHight + imgHeight; //当前card整个的高
      if (leftH == rightH || leftH < rightH) {
        //判断左右两侧当前的累计高度，来确定item应该放置在左边还是右边
        leftData.push(allData[i]);
        leftH += currentItemHeight;
      } else {
        rightData.push(allData[i]);
        rightH += currentItemHeight;
      }
    }

    leftData = this.leftList.concat(leftData);
    rightData = this.rightList.concat(rightData);
    //更新左右两栏的数据以及累计高度
    this.leftList = leftData;
    this.rightList = rightData;
    this.leftHight = leftH;
    this.rightHight = rightH;
  }
  goShowDetails(item: cloundtheater.VideoRecommendProductDto): void {
    this.$emit("goShowDetails", item);
  }
}
