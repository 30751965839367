
import { Component, Mixins } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Sticky, Tab, Tabs, PullRefresh, List, Icon } from "vant";
@Component({
  components: {
    [Sticky.name]: Sticky,
    [Tab.name]: Tab,
    [Tabs.name]: Tabs,
    [PullRefresh.name]: PullRefresh,
    [List.name]: List,
    [Icon.name]: Icon,
  },
})
export default class CloudTheatreDetails extends Mixins(Mixin) {
  activeIndex = 0; // tab选中的下标值
  categoryId: string | undefined = ""; // 选中的分类标签ID
  categoryList = [
    {
      id: "",
      name: "全部",
      hide: false,
    },
  ] as Array<cloundtheater.VideoCategoryDto>;
  videoList = [] as Array<cloundtheater.VideoDto>;
  isLoading = false;
  loading = false;
  finished = true;
  pages = 0;
  pagination = {
    page: 1,
    size: 20,
  };

  created() {
    if (this.$route.query.id) {
      this.categoryId = String(this.$route.query.id);
    }
  }

  mounted() {
    this.getCategories();
    this.getVideoList(true);
  }

  getCategories() {
    this.$api.cloundtheaterApi.cloundTheaterConfig.getVideoCategoryList(
      ({ data }) => {
        data.forEach((item) => {
          if (item.hide === false) {
            this.categoryList.push(item);
          }
        });
        this.$nextTick(() => {
          if (this.categoryId) {
            let index = this.categoryList.findIndex(
              (item) => item.id === this.categoryId
            );
            if (index !== -1) {
              this.activeIndex = index;
            }
          }
        });
      }
    );
  }

  changeCategoryId(index: number) {
    this.categoryId = this.categoryList[index].id;
    this.getVideoList(true);
  }

  getVideoList(first: boolean) {
    if (first) {
      this.pagination.page = 1;
    } else {
      this.pagination.page++;
    }
    this.$api.cloundtheaterApi.cloundTheaterVideo.searchVideos(
      {
        classifyId: String(this.categoryId),
        ...this.pagination,
      },
      ({ data }) => {
        this.isLoading = false;
        this.loading = false;
        this.pages = data.pages || 0;
        this.finished = this.pagination.page >= this.pages;
        if (data.records) {
          if (this.pagination.page === 1) {
            this.videoList = data.records;
          } else {
            this.videoList = this.videoList.concat(data.records);
          }
        }
      }
    );
  }

  formatNumByUnit(number?: number): string {
    let num: number = number ? number : 0;
    if (num < 10000) {
      return num + "";
    } else if (num >= 10000 && num < 100000000) {
      return (num / 10000).toFixed(2) + "万";
    } else {
      return (num / 100000000).toFixed(2) + "亿";
    }
  }
}
