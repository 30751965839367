
import { Component, Mixins, Prop } from "vue-property-decorator";
import { NavBar, Swipe, SwipeItem, Image, Row } from "vant";
import { Mixin } from "@/core/mixins/mixin";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Row.name]: Row,
  },
})
export default class AdvertsSwiper extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [];
    },
  })
  public advertList!: cms.TheaterAdvertDto[];

  /*
   * 跳转广告
   */
  goAdvert(item: cms.TheaterAdvertDto): void {
    this.$emit("goAdvert", item);
  }
}
