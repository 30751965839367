
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Image } from "vant";

@Component({
  components: {
    [NavBar.name]: NavBar,
    [Image.name]: Image,
  },
})
export default class CloudInfo extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public title!: string; //视频标题
  @Prop({
    type: String,
    default: "",
  })
  public subTitle!: string; //视频福标题
  @Prop({
    type: Number,
    default: 0,
  })
  public playCounts!: number;

  dealPlayCounts(playCounts: number): string {
    let num = "";
    if (playCounts < 10000) {
      num = playCounts.toString();
    } else if (10000 <= playCounts && playCounts < 100000000) {
      let number = playCounts / 10000;
      num = number.toFixed(1) + "万";
    } else if (playCounts > 100000000) {
      let number = playCounts / 100000000;
      num = number.toFixed(1) + "亿";
    }
    return num;
  }
}
