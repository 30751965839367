
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { Image } from "vant";
import ItemTitle from "@/views/Mall/Payment/ItemTitle.vue";
import Swiper, {
  Autoplay,
  EffectCoverflow,
  EffectCube,
  Pagination,
  Navigation,
} from "swiper";
Swiper.use([Autoplay, EffectCoverflow, EffectCube, Pagination, Navigation]);
import "swiper/swiper-bundle.min.css";
// swiper.less/sass/css 决定了基础的样式
import "swiper/swiper.less";
@Component({
  components: {
    [Image.name]: Image,
    ItemTitle,
  },
})
export default class CloudActorList extends Mixins(Mixin) {
  @Prop({
    type: Array,
    default: () => {
      return [
        {
          identityTags: ["演员"],
          img: "https://img.yzcdn.cn/vant/cat.jpeg",
          name: "猫",
          playRole: "加菲",
        },
      ];
    },
  })
  public actorList!: cloundtheater.CastMemberDto[];
  mounted(): void {
    this.initSwiper();
  }
  initSwiper(): void {
    new Swiper(".swiper1", {
      slidesPerView: "auto",
      spaceBetween: 10,
      slidesOffsetAfter: 30,
      autoplay: false,
      loop: false,
      on: {
        // click: function (swiper: any, event: any) {
        //   console.log(77, vm.num, 88, event);
        // },
      },
    });
  }
}
