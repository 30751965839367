
import { Component, Mixins, Prop } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import { NavBar, Row, Image } from "vant";
@Component({
  components: {
    [NavBar.name]: NavBar,
    [Row.name]: Row,
    [Image.name]: Image,
  },
})
export default class ItemTitle extends Mixins(Mixin) {
  @Prop({
    type: String,
    default: "",
  })
  public title!: string;
  @Prop({
    type: String,
    default: "",
  })
  public bottom!: string;
}
