
import { Mixin } from "@/core/mixins/mixin";
import { Component, Mixins, Prop, Watch } from "vue-property-decorator";
import { Image, Rate } from "vant";
@Component({
  components: {
    Mixin,
    [Image.name]: Image,
    [Rate.name]: Rate,
  },
})
export default class CommentItem extends Mixins(Mixin) {
  @Prop({
    default: () => {
      return {};
    },
    type: Object,
  })
  public commentItem!: member.ProductCommentDTO;
  @Prop({
    default: "",
    type: String,
  })
  public type?: string;
  public showMore = false;
  public hasMore = false;
  public showGif = false;

  @Watch("commentItem", { deep: true })
  changeComment() {
    this.$nextTick(() => {
      this.checkIsMoreLine();
    });
  }

  mounted() {
    this.$nextTick(() => {
      this.checkIsMoreLine();
    });
  }
  checkIsMoreLine() {
    const commentText: any = this.$refs.commentText;
    let elementHeight;
    if (commentText) {
      elementHeight = commentText.clientHeight;
    }
    const minHeight = (80 / 375) * window.innerWidth;
    this.hasMore = elementHeight > minHeight;
  }

  likePro(commentItem: member.ProductCommentDTO): void {
    if (!commentItem.likeNum) {
      this.showGif = true;
      setTimeout(() => {
        this.showGif = false;
      }, 1500);
    }
    // 云剧院的点赞和项目点赞不是同一个接口
    if (this.type === "cloud") {
      this.$emit("praise");
    } else {
      this.$api.memberApi.comment.praise(
        { commentId: commentItem.commentId! },
        ({ data }) => {
          commentItem.like = data.praise;
          commentItem.likeNum = data.likeNum;
        }
      );
    }
  }
}
