
import { Component, Mixins, Watch } from "vue-property-decorator";
import { Mixin } from "@/core/mixins/mixin";
import {
  Swipe,
  SwipeItem,
  Image,
  Icon,
  ImagePreview,
  Row,
  Popup,
  NoticeBar,
  Button,
  Tabs,
  Tab,
  Col,
} from "vant";
import CloudNav from "@/views/CloudTheatreDetails/components/CloudNav.vue";
import CloudVideo from "@/views/CloudTheatreDetails/components/CloudVideo.vue";
import CloudInfo from "@/views/CloudTheatreDetails/components/CloudInfo.vue";
import CloudAnthology from "@/views/CloudTheatreDetails/components/CloudAnthology.vue";
import CloudAdvert from "@/views/LimitActivities/components/AdvertsSwiper.vue";
import CloudActorList from "@/views/CloudTheatreDetails/components/CloudActorList.vue";
import CloudIntroduce from "@/views/CloudTheatreDetails/components/CloudIntroduce.vue";
import BrandsIntroduction from "@/views/CloudTheatreDetails/components/BrandsIntroduction.vue";
import CloudShow from "@/views/CloudTheatreDetails/components/CloudShow.vue";
import CloudVideoList from "@/views/CloudTheatreDetails/components/CloudVideoList.vue";
import payConfirm from "@/views/Payment/PayConfirm.vue";
import { Route, NavigationGuardNext } from "vue-router";
import Comment from "./components/Comment.vue";
@Component({
  components: {
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
    [Icon.name]: Icon,
    [ImagePreview.Component.name]: ImagePreview.Component,
    [Row.name]: Row,
    [Col.name]: Col,
    [Popup.name]: Popup,
    [NoticeBar.name]: NoticeBar,
    [Button.name]: Button,
    [Tabs.name]: Tabs,
    [Tab.name]: Tab,
    CloudNav,
    CloudVideo,
    CloudInfo,
    CloudAnthology,
    CloudAdvert,
    CloudActorList,
    CloudIntroduce,
    BrandsIntroduction,
    CloudShow,
    CloudVideoList,
    payConfirm,
    Comment,
  },
})
export default class CloudTheatreDetails extends Mixins(Mixin) {
  beforeRouteEnter(to: Route, from: Route, next: NavigationGuardNext): void {
    next((vm) => {
      //当分享出去的页面不展示返回按钮
      (vm as CloudTheatreDetails).isShowBack =
        from.matched && from.matched.length > 0;
    });
  }
  active = "detail";
  cloudInfo: cloundtheater.VideoInfoDto = {};
  patchInfoDtoList?: Array<cloundtheater.patchInfoDto> = [];
  vide = "";
  advertList: cms.TheaterAdvertDto[] = []; //广告列表
  copyrightImg = ""; //版权图
  showPayConfirm = false;
  scrollTop = 0;
  id = "";
  orderId = "";
  amount = 0;
  orderType = "";
  cloudKey = "1";
  isShowBack = true;
  showDownload = false;
  @Watch("$route", { immediate: true, deep: true })
  updateRouter(): void {
    this.allMethod();
  }
  allMethod(): void {
    let currentData = localStorage.getItem("downloadAppData");
    if (currentData !== this.getData()) {
      this.showDownload = true;
    } else {
      this.showDownload = false;
    }
    if (this.$refs.cloudAnthology) {
      //默认选择第一集
      (this.$refs.cloudAnthology as CloudAnthology).activeIndex = 0;
    }
    document.documentElement.scrollTop = 0;
    document.body.scrollTop = 0;
    this.id = this.$route.query.id ? String(this.$route.query.id) : "";
    this.patchInfoDtoList = [];
    this.cloudInfo = {};
    this.getCloudInfo();
    this.getSwiperList();
    this.getCopyrightImage();
  }

  onClickLeft(): void {
    this.$router.go(-1);
  }
  //是否自动播放
  getAutoplay(pay?: boolean, paid?: boolean): boolean {
    let play = true;
    if (pay) {
      if (paid) {
        play = true;
      } else {
        play = false;
      }
    } else {
      play = true;
    }

    return play;
  }
  //关闭下载弹窗
  closeDownLoad(): void {
    this.showDownload = false;
    localStorage.setItem("downloadAppData", this.getData());
  }
  // 下载app
  downloadApp(): void {
    this.showDownload = true;
    this.$router.push("/mine/download-app");
  }
  //获取当前时间
  getData(): string {
    let date = new Date();
    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate();
    return year + "-" + month + "-" + day;
  }
  /*
   * 获取详情
   */
  getCloudInfo(): void {
    this.$api.cloundtheaterApi.cloundTheaterVideo.getVideoInfoAndPatchInfoById(
      this.id,
      ({ data }) => {
        let result: any = data.videoInfoDto;
        this.cloudInfo = data.videoInfoDto!;
        // 是否为付费视频
        if (result.pay) {
          this.downloadApp();
          return;
        } else {
          if (result.videoEpisodes.length) {
            this.vide = result.videoEpisodes[0].url
              ? result.videoEpisodes[0].url
              : "";
          }
          // 是否播放贴片广告
          if (result.playPatchAdvert) {
            this.patchInfoDtoList = data.patchInfoDtoList;
          }
        }
        this.cloudKey = String(result.id);
        this.$nextTick(() => {
          if (this.$refs.videoIntroduction) {
            (this.$refs.videoIntroduction as CloudIntroduce).dealInfo();
          }
          if (this.$refs.brandIntroduction) {
            (this.$refs.brandIntroduction as BrandsIntroduction).dealInfo();
          }
        });
      }
    );
  }
  /*
   * 获取广告轮播列表
   */
  getSwiperList(): void {
    this.$api.cmsApi.advert.cityAdverts("CLOUD_THEATER_VIDEO", ({ data }) => {
      if (data) {
        this.advertList = data;
      }
    });
  }
  /*
   * 获取版权图
   */
  getCopyrightImage(): void {
    this.$api.cloundtheaterApi.cloundTheaterConfig.getCloundTheaterRenvation(
      ({ data }) => {
        this.copyrightImg = data.copyrightImg!;
      }
    );
  }
  /*
   * 去广告详情
   */
  goAdvert(item: cms.TheaterAdvertDto): void {
    this.goToJump(item.keyWord, item.linkId, item.linkType, item.linkUrl);
  }
  /*
   * 选集
   */
  selectVideo(data: cloundtheater.VideoEpisodeDto): void {
    this.cloudKey = data.id ? data.id : "1";
    this.vide = data.url ? data.url : "";
  }
  /*
   * 演出详情
   */
  goShowDetails(item: cloundtheater.VideoRecommendProductDto): void {
    this.goToProjectDetail(item.productId, item.tourId);
  }
  /*
   * 视频详情
   */
  goVideoDetails(id: string): void {
    this.$router.push(`/cloud-theatre-details?id=${id}&isBack=1`);
  }
  /*
   * 支付
   */
  goPay(): void {
    //是否在微博环境
    if (this.getIsWeiBo()) {
      return;
    }
    this.$api.orderApi.videoOrder.getOrderDetailVideo(this.id, ({ data }) => {
      let type: "SHOW" | "COMMODITY" | "VIDEO" = "VIDEO";
      let orderType = data.orderType;
      if (orderType == "ORDER_VIDEO") {
        type = "VIDEO";
      } else if (orderType == "ORDER_GOOD") {
        type = "COMMODITY";
      } else if (orderType == "ORDER_TICKET") {
        type = "SHOW";
      }
      this.showPayConfirm = true;
      this.$nextTick(() => {
        let payConfirm = this.$refs["pay-confirm"] as payConfirm;
        if (payConfirm) {
          payConfirm.getData({
            amount: data.videoAmt!,
            orderId: data.orderId!,
            recharge: true,
            orderType: type,
          });
        }
      });
    });
  }
}
